body {
    margin: 0px;
}
.App {
    text-align: center;
    height: 100%;
}
.title-bar {
    background-color: #800;
    color: #fff;
    padding: 5px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.content {
    display: flex;
    justify-content: center;
    align-items: center;
}
nav {
    background-color: #fcc;
}
nav ul {
    margin: 0px;
    display: flex;
    list-style-type: none;
}
nav li {
    padding: 15px;
}
nav li.selected {
    background-color: #fdd;
}
nav li:hover {
    background-color: #faa;
}
nav a {
    color: #444;
    text-decoration: none;
    padding: 10px;
}

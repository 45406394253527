button {
    cursor: pointer;
    border: none;
    background-color: #fdd;
    padding: 8px;
    border-radius: 8px;
}
.minesweeper {
    border-radius: 8px;
}

.status-bar {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
}
.status-bar > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.param {
    width: 80px;
}

.param label {
    color: #444;
}

.param input {
    width: 40px;
    border-radius: 8px;
    text-align: center;
    border: none;
    padding: 4px;
    background-color: #ccc;
}

/*
 *  game
 */

.game {
    border: 3px dashed #faa;
    border-radius: 8px;
}
.row {
    display: flex;
}
.square {
    width: 45px;
    height: 45px;
    border: 1px solid #fee;
    border-bottom: none;
    border-right: none;
    background-color: #fdd;
}
.square:first-child {
    border-left: none;
}

.row:first-child .square{
    border-top: none;
}
.row:last-child .square{
}

.game.running .square:hover {
    background-color: none;
    background: radial-gradient(#fff, #fdd);
    cursor: pointer;
}

.game.gameOver .square:hover {
    background-color: #fdd;
}

.game.running .square.revealed:hover {
    cursor: default;
    background: none;
}

.square .display {
    line-height: 45px;
}

.square.revealed {
    background: none;
}

.square.revealed:hover {
}

.square.hasMine {
    /*background-color: #555;*/
}

.square img {
    padding: 2px;
    width: 40px;
    height: 40px;
}

.hasMine img {
    width: 40px;
}

.square.lost, .square.lost:hover{
}

.mines-1 { color: #00b }
.mines-2 { color: #060 }
.mines-3 { color: #b00 }
.mines-4 { color: #606 }

.sudoku {
    border-radius: 8px;
}

.status-bar {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px 10px 30px;
}

.status-bar button {
    margin-bottom: 5px;
    background-color: #fdd;
    padding: 4px;
    color: #333;
    border: none;
    border-radius: 8px;
    width: 100px;
}

.status-bar button:hover {
    background-color: #fcc;
}

.solution-button {
    background-color: #fff;
    border: none;
    color: #55d;
    text-decoration: underline;
}

.board {
    border: 3px dashed #faa;
    border-radius: 8px;
}


.row {
    display: flex;
    border-bottom: 1px solid #fdd;
}

.row.border-bottom {
    border-bottom: 3px solid #fdd;
}

.row.border-bottom:last-child {
    border-bottom: none;
}

.cell {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #fdd;
}

.cell.border-right {
    border-right: 3px solid #fdd;
}

.cell.border-right:last-child {
    border-right: none;
}
.cell input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: #fff8f8;
    text-align:center;
    color: #777;
    font-size: 24px;
    cursor: default;
    caret-color: transparent;
    box-sizing: border-box;
}

.cell.non-locked input {
    background-color: #fff;
    cursor: pointer;
}

.cell.non-locked input:hover {
    background-color: #fee;
    color: #444;
}

.cell input:focus, .cell.non-locked input:focus:hover {
    outline: none;
    background-color: #fcc;
    color: #ddd;
}

.cell.non-locked.highlighted input{
    animation-name: highlight;
    animation-duration: 0.5s;
}

.cell.locked.highlighted input{
    animation-name: highlight-dark;
    animation-duration: 0.5s;
}

.cell.empty input{
    color: #aaa;
}

@keyframes highlight {
    from {
        background-color: #fff;
    }

    30% {
        background-color: #fcc;
    }

    50% {
        background-color: #fcc;
    }

    to {
        background-color: #fff;
    }
}

@keyframes highlight-dark {
    from {
        background-color: #fff8f8;
    }

    30% {
        background-color: #fcc;
    }

    50% {
        background-color: #fcc;
    }

    to {
        background-color: #fff8f8;
    }
}

.info {
    padding: 20px;
    color: white;
}
